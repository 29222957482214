.app-main-header {
  background-color: #ff5722 !important;
  color: #fff !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  background-color: #0370b7 !important;
}

.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 20px 10px 20px;
  color: #000;
}
.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}
.app-sidebar .side-nav {
  background: #fff !important;
  box-shadow: 5px 10px 18px #ced4da;
}
div.mini-drawer .app-main-header.custom-color {
  background: #0370b7 !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.app-sidebar .side-nav ul.nav-menu li a:hover,
.cbutton:hover {
  background: #0370b7;
  color: #fff;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #0370b7 !important;
  color: #000;
}
label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #0370b7;
}
.page-heading i.zmdi-hc-fw {
  color: #0370b7;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #0370b7;
  color: white;
}
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #0370b7 !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #0370b7 !important;
}

div#form-dialog-title {
  background: #0370b7;
}

/* Status labels*/
span.label {
  background: gray;
}

span.stripe {
  background: #1e70b7;
}
span.cash {
  background: #d15c0c;
}

span.confirmed,
span.pending,
span.created {
  background: #ffcb00e0;
}

span.searching {
  background: #e0b91ffc;
}

span.started {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick {
  background: #972ded;
}

span.assigned {
  background: turquoise;
}
span.return_on_route {
  background: rgb(245, 121, 142);
}
span.returned {
  background: rgb(194, 43, 194);
}

span.completed,
span.Online,
span.active,
span.approved,
span.Yes,
span.yes,
span.true {
  background: green;
}

span.cancelled,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.no,
span.false {
  background: #fd2121;
}

span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}
span.Offline {
  background: #eb4d4d;
}
